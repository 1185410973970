import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import DatabaseService from "../../firebase/db";
import { fbAnalytics } from "firebase/fbase";
import "../../assets/styles/custom.css";

function twoDigits(digit) {
  digit = digit < 10 ? "0" + digit + "" : digit + "";
  return digit;
}

export default function BusinessForm() {
  return (
    <>
      <Formik
        initialValues={{
          brand: "",
          category: "",
          manager: "",
          phone: "",
          email: "",
          desc: "",
          funnel: [],
          funnelEtc: "",
          privacy: false,
        }}
        validationSchema={Yup.object({
          brand: Yup.string().required("필수 입력 항목입니다."),
          phone: Yup.string("유효하지 않은 숫자 입니다.")
            .required("필수 입력 항목입니다.")
            .trim()
            .min(9, "유효하지 않은 숫자 입니다."),
          email: Yup.string()
            .email("유효하지 않은 이메일 입니다.")
            .required("필수 입력 항목입니다."),
          manager: Yup.string().required("필수 입력 항목입니다."),
          desc: Yup.string()
            .max(500, "최대 500글자까지만 작성하실 수 있습니다.")
            .required("필수 입력 항목입니다."),
          category: Yup.string().required("필수 입력 항목입니다."),
          funnel: Yup.array().min(1, "필수 입력 항목입니다."),
          funnelEtc: Yup.string().when("funnel", {
            is: (funnel) => funnel.includes("etc"),
            then: Yup.string().required(
              "'기타' 항목 선택 시 유입 경로 입력은 필수입니다."
            ),
            otherwise: Yup.string(),
          }),
          privacy: Yup.boolean().oneOf(
            [true],
            "개인정보수집에 동의하셔야 지원이 가능합니다."
          ),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          fbAnalytics.logEvent("select_content", {
            content_type: "applyBtn",
            content_id: "applyBtn",
          });
          const today = new Date();

          const year = today.getFullYear();
          let month = today.getMonth() + 1;
          month = twoDigits(month);
          let day = today.getDate();
          day = twoDigits(day);

          const refToday = year + month + day;
          const currentDate = year + "/" + month + "/" + day;

          let hour = today.getHours();
          let min = today.getMinutes();
          let sec = today.getSeconds();

          hour = twoDigits(hour);
          min = twoDigits(min);
          sec = twoDigits(sec);

          const currentTime = hour + ":" + min + ":" + sec;
          let err = false;
          // console.log("date: " + refToday);

          DatabaseService.create(
            "business",
            {
              createdAt: currentDate + " " + currentTime,
              brand: values.brand,
              phone: values.phone,
              email: values.email,
              manager: values.manager,
              category: values.category,
              desc: values.desc,
              funnel: values.funnel,
              funnelEtc: values.funnelEtc,
              policy: values.privacy,
            },
            (error) => {
              err = true;
              alert("에러가 발생하였습니다.\n에러메세지: " + error);
            }
          );

          if (!err) {
            alert("제출이 완료되었습니다!");
            setSubmitting(false);
            resetForm({});
          } else {
            alert("error \n " + values.funnel);
          }
        }}
      >
        {({ values }) => (
          <section className="border-none relative block py-24 lg:pt-0 bg-blueGray-800">
            <div className="container mx-auto px-4">
              <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
                <div className="w-full lg:w-8/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                    <div className="flex-auto p-5 lg:p-10">
                      <h4 className="mini:text-xl text-2xl font-semibold">
                        메이저스 비즈니스 협업 문의하기
                      </h4>
                      <p className="mini:text-sm leading-relaxed mt-1 mb-4 text-blueGray-500">
                        쇼츠/릴스/틱톡 성공적인 '숏폼' 광고 마케팅
                        <br />
                        No.1 숏폼 지식 MCN 메이저스와 함께 하세요!
                      </p>

                      <div className="font-normal mini:text-xxs text-xs leading-relaxed text-red-500">
                        * 표시 항목은 필수 입력 항목입니다. 미입력 시 지원이
                        완료되지 않습니다.
                      </div>

                      <Form>
                        {/* 브랜드(회사명) */}
                        <div className="relative w-full mb-5 mt-4">
                          <label
                            className="mini:text-xs block uppercase text-blueGray-600 text-sm font-bold mb-2"
                            htmlFor="brand"
                          >
                            브랜드(회사명){" "}
                            <span className="text-red-500">*</span>{" "}
                            <ErrorMessage
                              name="brand"
                              render={(msg) => (
                                <span className="form-err-msg font-normal align-middle inline-flex ml-5 text-xxs text-red-500">
                                  {msg}
                                </span>
                              )}
                            />
                          </label>
                          <Field
                            name="brand"
                            type="text"
                            className="mini:text-xs border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="브랜드(회사명)을 적어주세요"
                          />
                        </div>

                        {/* 카테고리 */}
                        <div className="relative w-full mb-5">
                          <label
                            className="mini:text-xs block uppercase text-blueGray-600 text-sm font-bold mb-2"
                            htmlFor="category"
                          >
                            카테고리 <span className="text-red-500">*</span>{" "}
                            <ErrorMessage
                              name="category"
                              render={(msg) => (
                                <span className="form-err-msg font-normal align-middle inline-flex ml-5 text-xxs text-red-500">
                                  {msg}
                                </span>
                              )}
                            />
                          </label>
                          <Field
                            name="category"
                            type="text"
                            placeholder="예: 캘리그라피, 드로잉, 외국어, IT 등"
                            className="mini:text-xs border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        </div>

                        {/* 담당자 */}
                        <div className="relative w-full mb-5">
                          <label
                            className="mini:text-xs block uppercase text-blueGray-600 text-sm font-bold mb-2"
                            htmlFor="manager"
                          >
                            담당자 <span className="text-red-500">*</span>{" "}
                            <ErrorMessage
                              name="manager"
                              render={(msg) => (
                                <span className="form-err-msg font-normal align-middle inline-flex ml-5 text-xxs text-red-500">
                                  {msg}
                                </span>
                              )}
                            />
                          </label>
                          <Field
                            name="manager"
                            type="text"
                            placeholder="담당자 이름을 적어주세요"
                            className="mini:text-xs border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        </div>

                        {/* 연락처 */}
                        <div className="relative w-full mb-5">
                          <label
                            className="mini:text-xs block uppercase text-blueGray-600 text-sm font-bold mb-2"
                            htmlFor="phone"
                          >
                            연락처 <span className="text-red-500">*</span>{" "}
                            <ErrorMessage
                              name="phone"
                              render={(msg) => (
                                <span className="form-err-msg font-normal align-middle inline-flex ml-5 text-xxs text-red-500">
                                  {msg}
                                </span>
                              )}
                            />
                          </label>
                          <Field
                            name="phone"
                            type="text"
                            placeholder="01012345678"
                            className="mini:text-xs border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        </div>

                        {/* 이메일 */}
                        <div className="relative w-full mb-5">
                          <label
                            className="mini:text-xs block uppercase text-blueGray-600 text-sm font-bold mb-2"
                            htmlFor="email"
                          >
                            이메일 <span className="text-red-500">*</span>{" "}
                            <ErrorMessage
                              name="email"
                              render={(msg) => (
                                <span className="form-err-msg font-normal align-middle inline-flex ml-5 text-xxs text-red-500">
                                  {msg}
                                </span>
                              )}
                            />
                          </label>
                          <Field
                            name="email"
                            type="email"
                            placeholder="email@email.com"
                            className="mini:text-xs border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        </div>

                        {/* 내용 */}
                        <div className="relative w-full mb-5">
                          <label
                            className="mini:text-xs block uppercase text-blueGray-600 text-sm font-bold mb-2"
                            htmlFor="desc"
                          >
                            내용
                          </label>
                          <Field
                            rows={1}
                            onInput={(e) => {
                              const target = e.target;
                              target.style.height = "auto";
                              target.style.height = `${target.scrollHeight}px`;
                            }}
                            name="desc"
                            type="text"
                            className="mini:text-xs border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                          <ErrorMessage
                            name="desc"
                            render={(msg) => (
                              <span className="ml-1 text-xxs text-red-500">
                                {msg}
                              </span>
                            )}
                          />
                        </div>

                        {/* 유입 경로 - SNS */}
                        <div role="group" className="relative w-full mb-3">
                          <label className="mini:text-xs block uppercase text-blueGray-600 text-sm font-bold mb-2">
                            유입 경로 <span className="text-red-500">*</span>{" "}
                            <ErrorMessage
                              name="funnel"
                              render={(msg) => (
                                <span className="form-err-msg font-normal align-middle inline-flex ml-5 text-xxs text-red-500">
                                  {msg}
                                </span>
                              )}
                            />
                          </label>
                          <div className="mini:text-xs block uppercase text-blueGray-600 text-xs font-normal mb-2 ml-2">
                            "기타" 항목 선택 시 우측 빈칸에 유입 경로를 꼭 입력
                            해주세요!
                          </div>

                          <label className="ml-4 inline-flex items-center cursor-pointer align-middle">
                            <Field
                              name="funnel"
                              type="checkbox"
                              value="instagram"
                              className="mini:checkbox form-checkbox border-0 rounded text-blueGray-700 w-5 h-5 ease-linear transition-all duration-150"
                            />
                            <span className="mini:text-xs ml-2 text-sm font-normal text-blueGray-600">
                              인스타그램
                            </span>
                          </label>

                          <label className="ml-4 inline-flex items-center cursor-pointer align-middle">
                            <Field
                              name="funnel"
                              type="checkbox"
                              value="facebook"
                              className="mini:checkbox form-checkbox border-0 rounded text-blueGray-700 w-5 h-5 ease-linear transition-all duration-150"
                            />
                            <span className="mini:text-xs ml-2 text-sm font-normal text-blueGray-600">
                              페이스북
                            </span>
                          </label>

                          <label className="ml-4 inline-flex items-center cursor-pointer align-middle">
                            <Field
                              name="funnel"
                              type="checkbox"
                              value="naverBlog"
                              className="mini:checkbox form-checkbox border-0 rounded text-blueGray-700 w-5 h-5 ease-linear transition-all duration-150"
                            />
                            <span className="mini:text-xs ml-2 text-sm font-normal text-blueGray-600">
                              네이버 블로그
                            </span>
                          </label>

                          <label className="ml-4 inline-flex items-center cursor-pointer align-middle">
                            <Field
                              name="funnel"
                              type="checkbox"
                              value="naverCafe"
                              className="mini:checkbox form-checkbox border-0 rounded text-blueGray-700 w-5 h-5 ease-linear transition-all duration-150"
                            />
                            <span className="mini:text-xs ml-2 text-sm font-normal text-blueGray-600">
                              네이버 카페
                            </span>
                          </label>

                          <label className="ml-4 inline-flex items-center cursor-pointer align-middle">
                            <Field
                              name="funnel"
                              type="checkbox"
                              value="article"
                              className="mini:checkbox form-checkbox border-0 rounded text-blueGray-700 w-5 h-5 ease-linear transition-all duration-150"
                            />
                            <span className="mini:text-xs ml-2 text-sm font-normal text-blueGray-600">
                              기사
                            </span>
                          </label>

                          <label className="ml-4 inline-flex items-center cursor-pointer align-middle">
                            <Field
                              name="funnel"
                              type="checkbox"
                              value="interview"
                              className="mini:checkbox form-checkbox border-0 rounded text-blueGray-700 w-5 h-5 ease-linear transition-all duration-150"
                            />
                            <span className="mini:text-xs ml-2 text-sm font-normal text-blueGray-600">
                              인터뷰
                            </span>
                          </label>
                        </div>

                        {/* 유입 경로 - 기타 */}
                        <div className="relative w-full mb-1 inline-flex align-middle">
                          <label className="mr-4 ml-4 inline-flex items-center cursor-pointer align-middle">
                            <Field
                              name="funnel"
                              type="checkbox"
                              value="etc"
                              className="mini:checkbox form-checkbox border-0 rounded text-blueGray-700 w-5 h-5 ease-linear transition-all duration-150"
                            />
                            <span className="mini:text-xs ml-2 text-sm font-normal text-blueGray-600">
                              기타
                            </span>
                          </label>
                          <Field
                            name="funnelEtc"
                            type="text"
                            placeholder="기타 선택 시 필수 입력"
                            className="w-1/2 mini:text-xs border-0 px-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                          />
                        </div>

                        {values.funnel.includes("etc") &
                        (values.funnelEtc === "") ? (
                          <ErrorMessage
                            name="funnelEtc"
                            render={(msg) => (
                              <div className="ml-5 mb-5 text-xxs text-red-500">
                                {msg}
                              </div>
                            )}
                          />
                        ) : (
                          <div className="mb-5"></div>
                        )}

                        {/* 개인정보수집 동의 */}
                        <div className="relative w-full mb-5">
                          <label className="inline-flex items-center cursor-pointer align-middle">
                            <Field
                              name="privacy"
                              type="checkbox"
                              className="mini:checkbox form-checkbox border-0 rounded text-blueGray-700 w-5 h-5 ease-linear transition-all duration-150"
                            />
                            <span className="mini:text-xs ml-2 text-sm font-semibold text-blueGray-600">
                              <a
                                href="https://majors.kr/privacy-policy"
                                className="text-lightBlue-600"
                                target="_blank"
                                rel="noreferrer"
                              >
                                개인 정보 수집
                              </a>
                              {""}에 동의합니다.
                            </span>
                          </label>
                          <ErrorMessage
                            name="privacy"
                            render={(msg) => (
                              <>
                                <div className="ml-3 mt-3 text-xxs text-red-500">
                                  {msg}
                                </div>
                              </>
                            )}
                          />
                        </div>

                        {/* 제출 버튼 */}
                        <div className="text-center mt-6">
                          <button
                            className="mini:text-xs bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="submit"
                          >
                            문의하기
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </Formik>
    </>
  );
}
