import React, { useState, useEffect } from "react";
import DatabaseService from "../../firebase/db";

const InfluencerList = () => {
  const [influencers, setInfluencers] = useState([]);

  const fetchInfluencers = async () => {
    const snapshot = await DatabaseService.getAllOnce("influencer");
    const influencersList = [];
    snapshot.forEach((doc) => {
      influencersList.push({ id: doc.key, ...doc.val() });
    });
    setInfluencers(influencersList);
  };

  useEffect(() => {
    fetchInfluencers();
  }, []);

  return (
    <div className="overflow-x-auto bg-white relative shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="py-3 px-6">
              이미지
            </th>
            <th scope="col" className="py-3 px-6">
              이름
            </th>
            <th scope="col" className="py-3 px-6">
              카테고리
            </th>
            <th scope="col" className="py-3 px-6">
              Instagram
            </th>
            <th scope="col" className="py-3 px-6">
              Tiktok
            </th>
            <th scope="col" className="py-3 px-6">
              YouTube
            </th>
            <th scope="col" className="py-3 px-6">
              작업
            </th>
          </tr>
        </thead>
        <tbody>
          {influencers.map((influencer) => (
            <tr
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              key={influencer.id}
            >
              <td className="py-4 px-6">
                <img
                  src={influencer.image}
                  style={{
                    height: "56px",
                    width: "56px",
                  }}
                  className="rounded-full"
                />
              </td>
              <td className="py-4 px-6">{influencer.name}</td>
              <td className="py-4 px-6">{influencer.category}</td>
              <td className="py-4 px-6">
                <a href={influencer.url?.instagram} target="_blank">
                  {influencer.url?.instagram || "N/A"}
                </a>
              </td>
              <td className="py-4 px-6">
                <a href={influencer.url?.tiktok} target="_blank">
                  {influencer.url?.tiktok || "N/A"}
                </a>
              </td>
              <td className="py-4 px-6">
                <a href={influencer.url?.youtube} target="_blank">
                  {influencer.url?.youtube || "N/A"}
                </a>
              </td>
              <td className="py-4 px-6">
                <button
                  onClick={() => {
                    DatabaseService.delete("influencer", influencer.id);
                    fetchInfluencers();
                  }}
                  className="font-medium text-red-600 dark:text-red-500 hover:underline"
                >
                  삭제
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InfluencerList;
