import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router";

// layouts
import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// firebase
import { authService } from "firebase/fbase";

export default function AdminRouter() {
  const [init, setInit] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    authService.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        console.log("not logged in");
        setIsLoggedIn(false);
      }
      setInit(true);
    });
  }, []);

  return (
    <Switch>
      {init ? (
        isLoggedIn ? (
          <Route path="/admin">
            <Admin />
          </Route>
        ) : (
          <Redirect from="/admin" to="/auth">
            <Auth />
          </Redirect>
        )
      ) : (
        ""
      )}
    </Switch>
  );
}
