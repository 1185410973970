import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

// components
// import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
// import FooterAdmin from "components/Footers/FooterAdmin.js";

// views
// import Dashboard from "views/admin/Dashboard.js";
// import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import BusinessTables from "views/admin/BusinessTables";
import InfluencerForm from "views/admin/InfluencerForm";
import InfluencerList from "views/admin/InfluencerList";
import SocialTraffics from "views/admin/SocialTraffics.js";
import ColumnForm from "views/admin/ColumnForm";
import ColumnList from "views/admin/ColumnList";

export default function Admin() {
  return (
    <>
      <Helmet>
        <title>Majors Network | Admin</title>
      </Helmet>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        {/* <AdminNavbar /> */}
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            {/* <Route path="/admin/dashboard" exact component={Dashboard} /> */}
            {/* <Route path="/admin/settings" exact component={Settings} /> */}
            <Route path="/admin/application" exact component={Tables} />
            <Route path="/admin/business" exact component={BusinessTables} />
            <Route path="/admin/influencer" exact component={InfluencerForm} />
            <Route path="/admin/column" exact component={ColumnForm} />
            <Route path="/admin/column/list" exact component={ColumnList} />
            <Route
              path="/admin/influencer/list"
              exact
              component={InfluencerList}
            />
            <Route
              path="/admin/socialtraffics"
              exact
              component={SocialTraffics}
            />
            <Redirect from="/admin" to="/admin/application" />
          </Switch>
          {/* <FooterAdmin /> */}
        </div>
      </div>
    </>
  );
}
