import React from "react";
import { Link } from "react-router-dom";

import "../../assets/styles/iconfonts.css";

export default function Footer() {
  return (
    <>
      <footer className="relative bg-blueGray-200 pt-8 pb-6">
        {/* 배경 사선 디자인 - Footer 상단 */}
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center text-center lg:text-center">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl font-semibold">Let's keep in touch!</h4>
              <div className="mt-6 lg:mb-0 mb-6">
                {/* Facebook */}
                <a
                  href="https://www.facebook.com/newzvibe"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="icon-facebook-colored align-middle"></i>
                  </button>
                </a>

                {/* Instagram */}
                <a
                  href="https://www.instagram.com/newcoming.kr"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    className="bg-white text-instagram shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="icon-instagram-colored align-middle"></i>
                  </button>
                </a>

                {/* TikTok */}
                <a
                  href="https://www.tiktok.com/@newzvibe"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    className="bg-white text-black shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="icon-tiktok align-middle"></i>
                  </button>
                </a>

                {/* Naver Blog */}
                <a
                  href="https://blog.naver.com/majors_kr"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    className="bg-white text-emerald-500 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="icon-naver align-middle"></i>
                  </button>
                </a>

                {/* 엠플래닛 */}
                <a
                  href="https://majors-planet.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    className="bg-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="icon-mplanet-purple align-middle text-xl"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>

          {/* Resources */}
          <div className="container px-4 mx-auto ">
            <div className="my-4 flex flex-wrap justify-center text-center">
              {/* <div className="w-auto px-4">
                <a
                  className="text-blueGray-500 hover:text-blueGray-800 font-normal text-xs"
                  href="https://newz.tech/"
                  target="_blank"
                  rel="noreferrer"
                >
                  주식회사 뉴즈
                </a>
              </div> */}
              <div className="w-auto px-4">
                <Link
                  to="/privacy-policy"
                  className="text-blueGray-500 hover:text-blueGray-800 font-normal text-xs"
                >
                  개인정보처리방침
                </Link>
              </div>
              <div className="w-auto px-4">
                <a
                  className="text-blueGray-500 hover:text-blueGray-800 font-normal text-xs"
                  href="mailto:contact@majors.kr?body=Sent from https://www.majors.kr"
                  // target="_blank"
                  // rel="noreferrer"
                >
                  Contact Us
                </a>
              </div>
            </div>
            <div className="my-6 flex flex-wrap justify-center text-center">
              <div className="w-auto px-4">
                <div className="text-blueGray-500 font-normal text-xs">
                  Copyright © {new Date().getFullYear()} 주식회사 뉴즈
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
