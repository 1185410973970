import React, { Component, createRef } from "react";
// import { Link } from "react-router-dom";
import { fbAnalytics } from "firebase/fbase";

// components
import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import BusinessForm from "components/ApplyForm/BusinessForm";
// import ImgModal from "components/Modals/ImgModal.js";

// style
import "../assets/styles/custom.css";
import "../assets/styles/iconfonts.css";
import { Link } from "react-router-dom";
import Influencer from "components/Influencer/Influencer";
import Videio from "../components/Video/Video";

export default class Landing extends Component {
  scrollForm = createRef();
  scrollCreators = createRef();

  BtnAnalytics = (type) => {
    fbAnalytics.logEvent("select_content", {
      content_type: "mplanetBtn",
      content_id: type,
    });
  };

  scrollSmoothHandler = () => {
    this.scrollForm.current.scrollIntoView({ behavior: "smooth" });
    fbAnalytics.logEvent("select_content", {
      content_type: "scrollToForm",
      content_id: "scrollToForm",
    });
  };

  scrollCreatorsHandler = () => {
    this.scrollCreators.current.scrollIntoView({ behavior: "smooth" });
    fbAnalytics.logEvent("select_content", {
      content_type: "scrollToCreator",
      content_id: "scrollToCreator",
    });
  };
  render() {
    return (
      <>
        <Navbar transparent />
        <main>
          <div className="border-none relative pt-32 lg:pt-48 pb-48 flex content-center items-center justify-center min-h-screen-75">
            <div
              className="absolute top-0 w-full h-full bg-center bg-cover"
              style={{
                backgroundImage:
                  "url(" +
                  require("assets/img/bg/majors-bg_resized.jpg").default +
                  ")",
              }}
            >
              <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-50 bg-black"
              ></span>
            </div>
            <div className="container relative mx-auto">
              <div className="items-center flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                  <div>
                    <h1 className="text-white font-semibold text-5xl xs:text-3xl">
                      숏폼 No.1 교육 MCN
                      <br />
                      <br />
                      메이저스 네트워크
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            {/* 배경 사선 디자인 - 크리에이터 배경화면 */}
            <div
              className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
              style={{ transform: "translateZ(0)" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-blueGray-200 border-none fill-current"
                  points="2560 0 2560 100 0 100"
                ></polygon>
              </svg>
            </div>
          </div>

          {/* Section #1 - Intro */}
          <section className="border-none pb-20 bg-blueGray-200 -mt-24">
            <div className="container mx-auto px-4">
              {/* Cards Section */}
              <div className="flex flex-wrap">
                {/* Card #1 */}
                <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-5 py-5 flex-auto">
                      <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                        <i className="icon-award text-xl"></i>
                      </div>
                      <h6 className="text-xl font-semibold">
                        틱톡 최초 공식 교육 MCN
                      </h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        2021년 상반기 틱톡 지식콘텐츠 부문
                        <br />
                        교육 MCN 1위 선정
                      </p>
                    </div>
                  </div>
                </div>

                {/* Card #2 */}
                <div className="w-full md:w-4/12 px-4 text-center">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-5 py-5 flex-auto">
                      <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                        <i className="icon-users"></i>
                      </div>
                      <h6 className="text-xl font-semibold">
                        에듀테인먼트 틱톡커 양성
                      </h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        어학, 교육, 금융, 테크, 의학, 헬스케어, 진로 등 다양한
                        분야 전문가 크리에이터 최다 보유
                      </p>
                    </div>
                  </div>
                </div>

                {/* Card #3 */}
                <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-5 py-5 flex-auto">
                      <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                        <i className="icon-trophy text-xl"></i>
                      </div>
                      <h6 className="text-xl font-semibold">
                        출범 7개월만에
                        <br />
                        조회수 3억뷰 돌파
                      </h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        틱톡을 이해하는 전문 교육MCN과 <br />
                        크리에이터의 만남
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Company Introduction */}
              <div className="flex flex-wrap items-center mt-32">
                {/* Left - video */}
                <Videio />

                {/* Right - Intro */}
                <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                  <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                    <i className="icon-rocket text-2xl"></i>
                  </div>
                  <h3 className="mini:text-2xl text-3xl mb-2 font-semibold leading-normal">
                    숏폼 크리에이터를 양성하는 메이저스 네트워크
                  </h3>
                  <p className="mini:text-sm xs:text-base text-lg font-normal leading-relaxed mt-4 mb-4 text-blueGray-600">
                    메이저스는 다양한 분야의 전문가들을 발굴하고 크리에이터로
                    양성하여 미래세대를 이끌어갈 Z세대에게 양질의 콘텐츠를
                    전하고 있습니다.
                  </p>
                  {/* To Do - 추후 크리에이터 페이지로 링크 */}
                  {/* <Link to="/" className="font-bold text-blueGray-700 mt-8">
                  메이저스의 크리에이터들을 확인해보세요!
                </Link> */}
                  <span
                    onClick={this.scrollCreatorsHandler}
                    className="mini:text-sm cursor-pointer font-bold text-blueGray-700 mt-8"
                  >
                    메이저스의 크리에이터들을 확인해보세요!{" "}
                    <i className="icon-hand-o-down text-lightBlue-600 mini:text-sm"></i>
                  </span>

                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="icon-check"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="mini:text-sm text-blueGray-500">
                            크리에이터의 매력을 드러낼 수 있는 기획
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="icon-check"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="mini:text-sm text-blueGray-500">
                            크리에이터를 위한 틱톡 콘텐츠 교육
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="icon-check"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="mini:text-sm text-blueGray-500">
                            메이저스 네트워크 활용 및 콘텐츠 피드백
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/* Section #2 - 프로모션 소개 */}
          <section
            id="business"
            ref={this.scrollForm}
            className="pt-20 pb-48 lg:pb-20 relative block bg-blueGray-800"
          >
            {/* 배경 사선 디자인 - 프로모션 소개 부분 상단 */}
            <div
              className="bottom-auto border-none top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
              style={{ transform: "translateZ(0)" }}
            >
              <svg
                className="absolute bottom-0 border-none overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-blueGray-800 border-none fill-current"
                  points="2560 0 2560 100 0 100"
                ></polygon>
              </svg>
            </div>

            {/* 프로모션 혜택 소개 */}
            <div className="container mx-auto px-4 lg:pb-64">
              <div className="flex flex-wrap text-center justify-center mb-24">
                <div className="w-full lg:w-8/12 px-4">
                  <h2 className="mini:text-3xl text-4xl font-semibold text-white">
                    메이저스 비즈니스
                    <br className="block m-hidden" />
                    협업 문의하기
                  </h2>
                </div>
              </div>

              {/* 프로모션 혜택 소개 - Row #1 */}
              <div className="flex flex-wrap justify-center">
                {/* <div className="w-full lg:w-3/12 mb-12 lg:mb-0 px-4 text-center">
                  <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                    <i className="fas fa-won-sign text-xl"></i>
                  </div>
                  <h6 className="text-xl mt-5 font-semibold text-white">
                    매달 장려금 지원
                    <br />( 틱톡 기준 합격 시 )
                  </h6>
                </div> */}
                <div className="w-full lg:w-3/12 mb-12 lg:mb-0 px-4 text-center">
                  <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                    <i className="icon-fire text-xl"></i>
                  </div>
                  <h5 className="text-xl mt-5 font-semibold text-white">
                    숏폼 No.1 지식 MCN
                  </h5>
                </div>

                <div className="w-full lg:w-3/12 mb-12 px-4 text-center">
                  <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                    <i className="icon-handshake-o text-xl"></i>
                  </div>
                  <h5 className="text-xl mt-5 font-semibold text-white">
                    브랜드 계정 기획/운영
                  </h5>
                </div>

                <div className="w-full lg:w-3/12 mb-12 lg:mb-0 px-4 text-center">
                  <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                    <i className="icon-video-camera text-xl"></i>
                  </div>
                  <h6 className="text-xl mt-5 font-semibold text-white">
                    숏폼 광고 제작(프로덕션)
                  </h6>
                </div>

                <div className="w-full lg:w-3/12 lg:mb-0 px-4 text-center">
                  <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                    <i className="icon-graduation-cap text-xl"></i>
                  </div>
                  <h5 className="text-xl mt-5 font-semibold text-white">
                    숏폼 컨설팅/기업강연
                  </h5>
                </div>
              </div>
              {/* 프로모션 혜택 소개 - Row #2 */}
              {/* <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 mb-12 lg:mb-0 px-4 text-center">
                  <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                    <i className="fas fa-video text-xl"></i>
                  </div>
                  <h6 className="text-xl mt-5 font-semibold text-white">
                    기존 SNS 콘텐츠
                    <br />
                    활용 가능
                  </h6>
                </div>
                <div className="w-full lg:w-3/12 px-4 text-center">
                  <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                    <i className="fas fa-people-arrows text-xl"></i>
                  </div>
                  <h5 className="text-xl mt-5 font-semibold text-white">
                    크리에이터 콜라보
                    <br />& 콘텐츠 피드백 지원
                  </h5>
                </div>
              </div> */}
            </div>
          </section>

          {/* Section #3 - 프로모션 지원 Form */}
          <BusinessForm />

          {/* Section #4 - 크리에이터 소개 */}
          <section
            ref={this.scrollCreators}
            className="border-none relative py-20 pt-20 pb-48"
          >
            {/* 배경 사선 디자인 - 크리에이터 소개 부분 상단 */}
            <div
              className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
              style={{ transform: "translateZ(0)" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 110"
                x="0"
                y="0"
              >
                <polygon
                  className="text-white border-none fill-current"
                  points="2560 0 2560 110 0 110"
                ></polygon>
              </svg>
            </div>

            {/* Creators List */}
            <Influencer />
          </section>

          {/* Apply Button */}
          <Link
            to="/apply"
            className="cursor-pointer fixed apply-btn sm:apply-btn xs:apply-btn mouse focus:outline-none"
          >
            <img
              loading="lazy"
              alt="..."
              src={require("assets/img/btn/creator_logo.png").default}
              className="mx-auto apply-img sm:apply-img xs:apply-img"
            />
          </Link>

          {/* 채용공고 버튼 (floting) */}
          {/* <a
            className="fixed floating-btn-1 sm:job-btn xs:job-btn mouse focus:outline-none"
            href="https://www.saramin.co.kr/zf_user/jobs/relay/view?rec_idx=41988519&view_type=mail_landing"
            target="_blank"
            rel="noreferrer"
            onClick={this.BtnAnalytics("job_floating")}
          >
            <img
              loading="lazy"
              alt="..."
              src={require("assets/img/btn/job-btn_rocket.png").default}
              className="mx-auto apply-img sm:apply-img xs:apply-img"
            />
          </a> */}

          {/* NewComming 버튼 */}
          <a
            className="fixed cursor-pointer floating-btn-1 mouse focus:outline-none"
            href="https://newcoming.kr/"
            target="_blank"
            rel="noreferrer"
            onClick={this.BtnAnalytics("mplanet_floating")}
          >
            <img
              loading="lazy"
              alt="..."
              src={require("assets/img/logo/new-coming-logo.png").default}
              className="mx-auto floating-btn-img sm:floating-btn-img xs:floating-btn-img"
            />
          </a>

          {/* <ImgModal /> */}
        </main>
        <Footer />
      </>
    );
  }
}
