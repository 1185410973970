import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./globals.css";

// import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

// import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Column from "views/Column";
import Post from "views/Post";
import AdminRouter from "layouts/AdminRouter";
import Privacy from "views/policy/Privacy";
// import Index from "views/Index.js";

// components
import ApplyForm from "components/ApplyForm/ApplyForm";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/admin" component={AdminRouter} />
      <Route path="/auth" component={Auth} />
      <Route path="/privacy-policy" component={Privacy} />
      <Route path="/apply" component={ApplyForm} />
      <Route path="/posts/:id" component={Post} />
      <Route path="/posts" component={Column} />
      {/* add routes without layouts */}
      <Route path="/" component={Landing} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
