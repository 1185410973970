/*eslint-disable*/
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { fbAnalytics } from "firebase/fbase";

import "../../assets/styles/iconfonts.css";

// function BtnAnalytics(type) {
//   fbAnalytics.logEvent("select_content", {
//     content_type: "jobBtn",
//     content_id: type,
//   });
// }

function BtnAnalytics(type) {
  fbAnalytics.logEvent("select_content", {
    content_type: "MPlanetBtn",
    content_id: type,
  });
}

export default function Navbar(props) {
  const scrollToSection = (sectionId, e) => {
    e.preventDefault();
    document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-auto static block justify-start">
            <Link
              className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
              to="/"
            >
              {/* Majors Network */}
              <img
                alt="..."
                src={require("assets/img/logo/majors-logo_60.png").default}
                className="mx-auto"
              />
            </Link>
          </div>
          <div
            className={
              "flex flex-grow items-center bg-opacity-0 shadow-none rounded"
            }
          >
            {/* Social Links */}
            <ul className="flex flex-row list-none ml-auto">
              <li className="flex items-center align-middle">
                <Link
                  className="mplanet-btn-bg text-blueGray-700 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-0 ease-linear transition-all duration-150"
                  to="/posts"
                  onClick={BtnAnalytics("navbar_button")}
                >
                  {/* <i
                    className="align-middle icon-mplanet-ori"
                    aria-label="엠플래닛"
                  /> */}
                  <div className="cursor-pointer inline-block">
                    메이저스 칼럼
                  </div>
                </Link>
              </li>

              {/* Majors Planet 링크 버튼 */}
              <li className="flex items-center align-middle">
                <div
                  className="mplanet-btn-bg text-blueGray-700 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-0 ease-linear transition-all duration-150"
                  onClick={BtnAnalytics("navbar_button")}
                >
                  {/* <i
                    className="align-middle icon-mplanet-ori"
                    aria-label="엠플래닛"
                  /> */}
                  <div
                    onClick={(e) => scrollToSection("business", e)}
                    className="cursor-pointer inline-block"
                  >
                    광고 문의
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
