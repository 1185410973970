import firebase from "./fbase";

const db = firebase.database().ref("applications");

class DatabaseService {
  getAllOnce(path) {
    const dbRef = firebase.database().ref(`applications/${path}`);
    return dbRef.once("value");
  }
  getAllApplications() {
    const dbRef = firebase.database().ref(`applications`);
    return dbRef.once("value");
  }
  getPost(path, key) {
    return firebase.database().ref(`applications/${path}/${key}`).once("value");
  }

  getCardStats() {
    return db.once("value");
  }

  create(ref, data) {
    return db.child(ref).push().set(data);
  }

  createPost(path, key, data) {
    return firebase.database().ref(`applications/${path}/${key}`).set(data);
  }
  editPost(path, key, data) {
    return firebase.database().ref(`applications/${path}/${key}`).update(data);
  }

  update(key, value) {
    return db.child(key).update(value);
  }

  delete(path, key) {
    return firebase.database().ref(`applications/${path}/${key}`).remove();
  }

  async checkKeywordUnique(path, keyword) {
    console.log(path, keyword);
    const dbRef = firebase.database().ref(`applications/${path}`);
    try {
      const snapshot = await dbRef.child(keyword).once("value");
      return !snapshot.exists();
    } catch (error) {
      console.error("Error checking keyword uniqueness:", error);
      return false;
    }
  }
}

export default new DatabaseService();
