import React from "react";

function funnelParse(value) {
  switch (value) {
    case "instagram":
      return <i className="icon-instagram-colored mx-1"></i>;
    case "facebook":
      return <i className="icon-facebook-colored mx-1"></i>;
    case "naverBlog":
      return <i className="icon-naver mx-1">블로그</i>;
    case "naverCafe":
      return <i className="icon-naver mx-1">카페</i>;
    case "article":
      return <i className="icon-newspaper-o mx-1"></i>;
    case "interview":
      return "인터뷰";
    case "etc":
      return "";
    default:
      return "-";
  }

  // return iTagClass;
}

export const CardTableTd = ({ item, path }) => {
  let funnelArr = [];
  if (item.funnel === undefined) funnelArr.push("");
  else funnelArr = item.funnel;

  const dateEdited = item.createdAt;
  return (
    <>
      <tr className="border-b-1">
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap md:whitespace-normal p-4 text-center">
          {dateEdited}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4 text-center break-words">
          {path === "apply" ? item.name : item.brand}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4 text-center break-words">
          {item.phone}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4 text-center larger_td">
          {item.email}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4 text-center break-words">
          {item.category}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4 text-center">
          {path === "apply" ? (
            <a href={item.snsurl} target="_blank" rel="noreferrer">
              {item.snsurl}
            </a>
          ) : (
            item.desc
          )}
          {/* <i className="ml-3 align-middle icon-copy"></i> */}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4 text-center break-words">
          {path === "apply"
            ? item.cert === ""
              ? "없음"
              : item.cert
            : item.manager}
        </td>
        {path === "apply" ? (
          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
            {item.naver ? (
              <i className="icon-circle text-emerald-500"></i>
            ) : (
              <i className="icon-circle text-red-500"></i>
            )}
          </td>
        ) : null}
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4 text-center break-words">
          {funnelArr.includes("-")
            ? "-"
            : funnelArr.map((value, idx) => funnelParse(value))}
          {<div>{item.funnelEtc}</div>}
        </td>
      </tr>
    </>
  );
};

export default CardTableTd;
