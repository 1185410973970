import React, { useEffect, useState } from "react";
import DatabaseService from "../firebase/db";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import AuthNavbar from "../components/Navbars/AuthNavbar";

const PostListHeader = styled.div`
  background-color: #000;
  color: #fff;
  padding: 3rem 1rem;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1rem;
`;

const PostCard = styled.div`
  background: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .content {
    padding: 1rem;

    h5 {
      margin-bottom: 0.5rem;
      color: #333;
    }

    p {
      color: #666;
    }
  }
`;

const CategoryLabel = styled.div`
  padding-left: 1rem;
  font-size: 24px;
  font-weight: bold;
  border-bottom: 3px solid #000;
  width: 200px;
  padding-bottom: 0.5rem;
  text-align: end;
`;

const Column = () => {
  const domParser = new DOMParser();
  const [postList, setPostList] = useState([]);

  const getPosts = async () => {
    const data = await DatabaseService.getAllOnce("post");
    const posts = [];
    data.forEach((doc) => {
      posts.push({ id: doc.key, ...doc.val() });
    });
    setPostList([...posts].reverse());
  };

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <>
      <div
        style={{
          background: "#000",
          width: "100%",
          height: "100px",
        }}
      >
        <AuthNavbar />
      </div>
      <PostListHeader>숏폼 No.1 교육 MCN 메이저스 네트워크</PostListHeader>
      <CategoryLabel>마케팅 칼럼</CategoryLabel>
      <GridContainer>
        {postList.map((post) => (
          <PostCard key={post.id}>
            <Link to={`/posts/${post.id}`}>
              <div>
                <img
                  src={
                    domParser
                      .parseFromString(post.content, "text/html")
                      .querySelector("img")?.src
                  }
                  alt=""
                />
              </div>
              <div className="content">
                <h5>{post.title}</h5>
                <p>
                  {post.content.length > 50
                    ? `${domParser
                        .parseFromString(post.content, "text/html")
                        .querySelector("p")
                        ?.innerText.substring(0, 50)}...`
                    : domParser
                        .parseFromString(post.content, "text/html")
                        .querySelector("p")?.innerText}
                </p>
              </div>
            </Link>
          </PostCard>
        ))}
      </GridContainer>
    </>
  );
};

export default Column;
