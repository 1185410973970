import React, { useEffect, useState } from "react";
import HtmlToReact from "html-to-react";
import styled from "styled-components";
import DatabaseService from "../firebase/db";
import { useParams } from "react-router-dom";
import AuthNavbar from "../components/Navbars/AuthNavbar";

// Styled components for the page
const PostContainer = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
`;

const Content = styled.div`
  font-size: 1rem;
  color: #666;
  line-height: 1.6;

  /* Styles for paragraphs */
  p {
    margin-bottom: 15px;
  }

  /* Styles for headings inside the content */
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #444;
  }

  /* Styles for images */
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px auto;
  }

  /* Styles for links */
  a {
    color: #0066cc;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Post = () => {
  const [post, setPost] = useState(null);

  const { id } = useParams();

  const getPosts = async () => {
    const data = await DatabaseService.getPost("post", id);
    setPost({
      title: data.val().title,
      content: data.val().content,
    });
  };

  useEffect(() => {
    getPosts();
  }, []);
  const htmlToReactParser = new HtmlToReact.Parser();
  const reactElement = htmlToReactParser.parse(post?.content);

  return (
    <>
      <div
        style={{
          background: "#000",
          width: "100%",
          height: "100px",
        }}
      >
        <AuthNavbar />
      </div>
      <PostContainer>
        <Title>{post?.title}</Title>
        <Content>{reactElement}</Content>
      </PostContainer>
    </>
  );
};

export default Post;
