import React, { useState, useEffect } from "react";
import DatabaseService from "../../firebase/db";

const Influencer = () => {
  const [influencers, setInfluencers] = useState([]);

  useEffect(() => {
    const cachedInfluencers = sessionStorage.getItem("influencers");
    if (cachedInfluencers) {
      setInfluencers(JSON.parse(cachedInfluencers));
    } else {
      fetchInfluencers();
    }
  }, []);

  const fetchInfluencers = async () => {
    const snapshot = await DatabaseService.getAllOnce("influencer");
    const influencersList = [];
    snapshot.forEach((doc) => {
      influencersList.push({ id: doc.key, ...doc.val() });
    });
    setInfluencers(influencersList);
    sessionStorage.setItem("influencers", JSON.stringify(influencersList));
  };

  return (
    <div className="container mx-auto px-4">
      <div className="flex flex-wrap justify-center text-center mb-24">
        <div className="w-full lg:w-8/12 px-4">
          <h2 className="creator-text font-semibold xl:creator-text lg:creator-text">
            ’10년 뒤 리더가 될 미래세대를 위한
            <br className="br-hidden" /> 콘텐츠를 전하자’는{" "}
            <br className="mb-2 bg-lg-hidden" />
            사명감으로
            <br className="br-hidden" /> 대중의 신뢰를 얻는 정보성 콘텐츠를
            만듭니다.
          </h2>
        </div>
      </div>

      {/* Creators Row #1 */}
      <div className="flex flex-wrap mb-24">
        {influencers?.map((i) => (
          <div
            key={i.id}
            className="w-full md:w-6/12 lg:w-3/12 lg:mb-4 mb-12 px-4"
          >
            <div className="px-6">
              <img
                loading="lazy"
                alt="..."
                src={i.image}
                style={{
                  width: 240,
                  height: 320,
                  objectFit: "cover",
                }}
                className="shadow-lg mx-auto"
              />

              <div className="pt-6 text-center">
                <h5 className="text-2xl font-bold">{i.name}</h5>
                <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                  {i.category}
                </p>
                <div className="mt-6">
                  {/* TikTok */}
                  {i.url.tiktok && i.url.tiktok !== "" ? (
                    <a href={i.url.tiktok} target="_blank" rel="noreferrer">
                      <button
                        className="bg-black text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-2 mb-1"
                        type="button"
                      >
                        <i className="icon-tiktok align-middle"></i>
                      </button>
                    </a>
                  ) : null}
                  {i.url.youtube && i.url.youtube !== "" ? (
                    <a href={i.url.youtube} target="_blank" rel="noreferrer">
                      <button
                        className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-2 mb-1"
                        type="button"
                      >
                        <i className="icon-youtube align-middle"></i>
                      </button>
                    </a>
                  ) : null}
                  {i.url.instagram && i.url.instagram !== "" ? (
                    <a href={i.url.instagram} target="_blank" rel="noreferrer">
                      <button
                        className="bg-instagram text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-2 mb-1"
                        type="button"
                      >
                        <i className="icon-instagram align-middle"></i>
                      </button>
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Influencer;
