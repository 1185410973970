import React, { useState, useEffect } from "react";
import DatabaseService from "../../firebase/db";
import { useWriting } from "hooks/useWriting";
import { withRouter } from "react-router-dom";

const ColumnList = ({ history }) => {
  const { setWritingData } = useWriting();

  const [column, setColumn] = useState([]);

  const fetchColumn = async () => {
    const snapshot = await DatabaseService.getAllOnce("post");
    const columnList = [];
    snapshot.forEach((doc) => {
      columnList.push({ id: doc.key, ...doc.val() });
    });
    setColumn(columnList);
  };

  useEffect(() => {
    fetchColumn();
  }, []);

  return (
    <div className="overflow-x-auto bg-white relative shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr className="flex items-center">
            <th scope="col" className="py-3 px-6 flex-1">
              id
            </th>
            <th scope="col" className="py-3 px-6 flex-1">
              제목
            </th>
            <th scope="col" className="py-3 px-6 flex-1">
              컨텐츠
            </th>
            <th scope="col" className="py-3 px-6 flex-1">
              작업
            </th>
          </tr>
        </thead>
        <tbody>
          {column.map((column) => (
            <tr
              className="bg-white items-center border-b flex dark:bg-gray-800 dark:border-gray-700"
              key={column.id}
            >
              <td className="py-4 px-6 flex-1">{column.id}</td>
              <td className="py-4 px-6 flex-1">{column.title}</td>
              <td className="py-4 px-6 flex-1">
                {column.content.length > 40
                  ? `${column.content.substring(0, 40)}...`
                  : column.content}
              </td>
              <td className="py-4 px-6 flex-1">
                <div className="flex items-center">
                  <button
                    onClick={() => {
                      setWritingData(column);
                      history.push("/admin/column");
                    }}
                    className="font-medium mr-4 text-red-600 dark:text-red-500 hover:underline"
                  >
                    편집
                  </button>
                  <button
                    onClick={() => {
                      DatabaseService.delete("post", column.id);
                      fetchColumn();
                    }}
                    style={{
                      color: "red",
                    }}
                    className="font-medium text-red-600 dark:text-red-500 hover:underline"
                  >
                    삭제
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default withRouter(ColumnList);
