import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useRef, useState } from "react";
import DatabaseService from "../../firebase/db";
import { useWriting } from "hooks/useWriting";

export default function ColumnForm() {
  const editorRef = useRef(null);
  const { writingData } = useWriting();

  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [keyword, setKeyword] = useState("");

  const checkKeywordUnique = async (path, keyword) => {
    const isUnique = await DatabaseService.checkKeywordUnique(path, keyword);
    return isUnique;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const isUnique = await checkKeywordUnique("post", keyword);
    if (!writingData && !isUnique) {
      alert("이미 사용 중인 키워드입니다. 다른 키워드를 입력해주세요.");
      return;
    }

    writingData
      ? DatabaseService.editPost("post", keyword, {
          title,
          content,
        })
      : DatabaseService.createPost("post", keyword, {
          title,
          content,
        });

    if (editorRef.current) {
      const editor = editorRef.current;

      editor.setContent("");
    }

    setContent("");
    setTitle("");
    setKeyword("");
  };

  useEffect(() => {
    if (writingData) {
      setTitle(writingData.title);
      setKeyword(writingData.id);
    }
  }, [writingData]);

  return (
    <form onSubmit={submitHandler} className="w-full h-full flex flex-col">
      <div className="flex items-center" style={{ zIndex: 999 }}>
        <input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full h-10"
          type="text"
        />
      </div>
      <Editor
        apiKey={process.env.REACT_APP_TINY_API_KEY}
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        onEditorChange={(content) => {
          setContent(content);
        }}
        initialValue={writingData?.content}
        init={{
          height: 550,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "fontsize",
            "image",
            "fontfamily",
            "code",
          ],
          toolbar:
            "undo redo | " +
            "fontsize bold italic forecolor | fontfamily alignleft aligncenter |" +
            "alignright alignjustify | bullist numlist | image code",
          content_style: "body { font-family:Inter; font-size:16px }",
          skin: "oxide",
          content_css: "light",
          font_size_formats: "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
        }}
      />
      <div className="flex mt-4 items-center justify-between w-full">
        <div className="flex flex-col gap-2">
          <span>키워드를 설정해주세요. (url에 반영됩니다.)</span>
          <input
            type="text"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
        </div>
        <button
          type="submit"
          style={{
            background: "#000",
            color: "#fff",
            width: "200px",
          }}
          className="py-2 px-10 cursor-pointer"
        >
          {writingData ? "편집" : "제출"}
        </button>
      </div>
    </form>
  );
}
