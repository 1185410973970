import React from "react";
import { Helmet } from "react-helmet";

// components
import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";
// import Footer from "components/Footers/Footer.js";

import "../../assets/styles/custom.css";

export default function privacy() {
  return (
    <>
      <Helmet>
        <title>Majors Network | 개인정보처리방침</title>
      </Helmet>
      <Navbar transparent />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          {/* Background Image */}
          <div className="absolute top-0 w-full h-full bg-blueGray-800"></div>

          {/* Content */}
          <div className="container relative mx-auto px-6 py-6 h-full text-white policy-font">
            {/* Header */}
            <div className="space-y-4">
              <div className="block w-full h-full text-5xl py-3 font-bold">
                개인정보처리방침
              </div>
              <div className="block w-full h-full py-3 text-sm">
                (주)뉴즈(이하 ‘회사')는 웹사이트 (
                <a href="https://majors.kr" target="_blank" rel="noreferrer">
                  https://majors.kr
                </a>{" "}
                이하 '메이저스 네트워크') 이용 및 제반 서비스 제공시 「개인정보
                보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와
                관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여
                다음과 같이 개인정보 처리방침을 수립·공개합니다.
              </div>
            </div>
            <hr className="my-4 border-blueGray-600" />

            {/* 조항 */}
            <ul className="list-none">
              {/* 제1조 */}
              <li className="space-y-4 mb-4">
                <div className="block w-full h-full text-xl py-3 font-bold">
                  제1조 [개인정보 수집에 대한 동의]
                </div>
                <div className="block w-full h-full py-3 text-sm mb-4">
                  회사는 이용자가 개별 서비스 신청시 개인정보 수집 및 이용에
                  동의할 수 있는 절차를 마련하고 있습니다. 이용자는 동의를
                  거부할 수 있으며 동의를 거부하는 경우 해당 서비스 이용이
                  제한될 수 있습니다.
                </div>
              </li>

              {/* 제2조 */}
              <li className="space-y-4 mb-4">
                <div className="block w-full h-full text-xl py-3 font-bold">
                  제2조 [개인정보 수집 및 이용 목적]
                </div>
                <div className="block w-full h-full py-3 text-sm">
                  회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고
                  있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며
                  이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에
                  따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                </div>
                <table className="table-auto w-full bg-transparent border-collapse">
                  <thead>
                    <tr>
                      <th className="px-6 align-middle border border-solid py-3 border-l-0 font-semibold border-blueGray-100">
                        구분
                      </th>
                      <th className="px-6 align-middle border border-solid py-3 border-l-0 border-r-0 font-semibold border-blueGray-100">
                        이용목적
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 p-4">
                        크리에이터 지원시
                      </td>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 border-r-0 p-4">
                        크리에이터 지원 의사 확인 및 본인 식별, 서비스 부정이용
                        방지, 각종 고지 및 안내, 분쟁 조정을 위한 기록 보존
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>

              {/* 제3조 */}
              <li className="space-y-4 mb-4">
                <div className="block w-full h-full text-xl py-3 font-bold">
                  제3조 [개인정보 수집 항목 및 방법]
                </div>
                <div className="block w-full h-full text-lg py-3">
                  가. 개인정보 수집 항목
                </div>
                <div className="block w-full h-full py-3 text-sm">
                  회사는 기본적인 서비스 제공을 위한 필수 정보만을 수집하고
                  있으며, 선택 정보의 경우 입력하지 않더라도 서비스 이용이
                  제한되지 않습니다. 회사는 수집한 정보를 이용 목적 외의
                  목적으로 사용하지 않습니다.
                </div>
                <table className="table-auto w-full bg-transparent border-collapse mt-3 mb-3">
                  <thead>
                    <tr>
                      <th className="px-6 align-middle border border-solid py-3 border-l-0 font-semibold border-blueGray-100">
                        구분
                      </th>
                      <th className="px-6 align-middle border border-solid py-3 border-l-0 border-r-0 font-semibold border-blueGray-100">
                        수집 및 이용 항목
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 p-4">
                        크리에이터 지원시
                      </td>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 border-r-0 p-4">
                        <b>(필수)</b> 이름, 전화번호(휴대전화번호 또는
                        자택전화번호), 이메일, 소셜 네트워크 채널 주소, 콘텐츠
                        카테고리 <br />
                        <b>(선택)</b> 보유 자격증명 및 증빙자료, 네이버
                        인플루언서 등록 여부
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="block w-full h-full text-lg py-3">
                  나. 개인정보 수집 방법
                </div>
                <div className="block w-full h-full py-3 text-sm">
                  회사는 홈페이지를 통한 이용자의 각 신청서 작성을 통해
                  개인정보를 수집합니다.
                </div>
              </li>

              {/* 제4조 */}
              <li className="space-y-4 mb-4">
                <div className="block w-full h-full text-xl py-3 font-bold">
                  제4조 [개인정보 보유 및 이용기간]
                </div>
                <div className="block w-full h-full py-3 text-sm">
                  회사는 이용자의 개인정보 수집 및 이용 동의일로부터 1년간
                  이용자의 크리에이터 지원 관련한 개인정보를 처리 및 보유합니다.
                  단, 관계법령의 규정에 따라 개인정보를 보유하여야 할 필요가
                  있을 경우 일정기간 보유되며 이때 보유되는 개인정보의 열람 및
                  이용은 해당 사유로 제한됩니다.
                </div>
                <table className="table-fixed w-full bg-transparent border-collapse">
                  <thead>
                    <tr>
                      <th className="w-1/5 px-6 align-middle border border-solid py-3 border-l-0 font-semibold border-blueGray-100">
                        유형
                      </th>
                      <th className="w-1/5 px-6 align-middle border border-solid py-3 border-l-0 font-semibold border-blueGray-100">
                        항목
                      </th>
                      <th className="w-2/5 px-6 align-middle border border-solid py-3 border-l-0 font-semibold border-blueGray-100">
                        보유 근거
                      </th>
                      <th className="w-1/5 px-6 align-middle border border-solid py-3 border-l-0 border-r-0 font-semibold border-blueGray-100">
                        보유 기간
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 p-4">
                        기타 정보
                      </td>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 p-4">
                        크리에이터 지원 정보
                      </td>
                      <td className="order-t-0 border border-solid border-blueGray-100 align-middle px-6 p-4 border-l-0">
                        크리에이터 지원 의사 확인 및 본인 식별, 서비스 부정이용
                        방지, 각종 고지 및 안내, 분쟁 조정
                      </td>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 border-r-0 p-4">
                        1년
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>

              {/* 제5조 */}
              <li className="space-y-4 mb-4">
                <div className="block w-full h-full text-xl py-3 font-bold">
                  제5조 [개인정보의 파기]
                </div>
                <div className="block w-full h-full py-3 text-sm">
                  ① 회사는 원칙적으로 개인정보 처리 목적이 달성되어 개인정보
                  처리가 불필요하다고 인정되는 경우와 이용자가 개인정보의 파기를
                  요청한 경우에는 지체 없이 해당 개인정보를 파기합니다. 파기의
                  절차, 기한 및 방법은 다음과 같습니다.
                  <br />
                  <br />② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나
                  처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를
                  계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의
                  데이터베이스(DB) 또는 서류함으로 옮겨 내부 방침 및 기타 관련
                  법령에 따라 지체없이 파기됩니다. 이때 내부 방침에 따라 별도DB
                  또는 문서로 옮겨진 개인정보는 법률에 의한 경우를 제외하고는
                  이용되지 않습니다.
                  <br />
                  <br />
                  ③ 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는
                  보유기간의 종료일로부터 5일 이내에, 개인정보처리 목적달성,
                  해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게
                  되었을 때에는 개인정보 처리가 불필요한 것으로 인정되는
                  날로부터 5일 이내에 파기합니다.
                  <br />
                  <br />④ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
                </div>

                <div className="block w-full h-full py-3 text-sm mb-4">
                  1. 파기절차
                  <br />
                  <br />
                  회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보
                  보호책임자의 승인을 받아 개인정보를 파기합니다.
                  <br />
                  <br />
                  2. 파기방법
                  <br />
                  <br />
                  전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을
                  사용합니다.
                  <br />
                  <br />
                  종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
                  파기합니다
                </div>
              </li>

              {/* 제6조 */}
              <li className="space-y-4 mb-4">
                <div className="block w-full h-full text-xl py-3 font-bold">
                  제6조 [정보주체와 법정대리인의 권리·의무 및 그 행사방법]
                </div>
                <div className="block w-full h-full py-3 text-sm">
                  ① 정보주체는 회사에 대해 언제든지 개인정보
                  열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
                  <br />
                  <br />② 제1항에 따른 권리 행사는 회사에 대해 「개인정보
                  보호법」 시행령 제41조제1항에 따라 서면, 전자우편,
                  모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체
                  없이 조치하겠습니다.
                  <br />
                  <br />③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나
                  위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우
                  “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호
                  서식에 따른 위임장을 제출하셔야 합니다.
                  <br />
                  <br />④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」
                  제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될
                  수 있습니다.
                  <br />
                  <br />⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그
                  개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를
                  요구할 수 없습니다.
                  <br />
                  <br />⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의
                  요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나
                  정당한 대리인인지를 확인합니다.
                </div>
              </li>

              {/* 제7조 */}
              <li className="space-y-4 mb-4">
                <div className="block w-full h-full text-xl py-3 font-bold">
                  제7조 [개인정보의 안전성 확보 조치]
                </div>
                <div className="block w-full h-full text-sm">
                  회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
                  있습니다.
                </div>

                <div className="block w-full h-full text-lg py-3">
                  가. 내부관리계획의 수립 및 시행
                </div>
                <div className="block w-full h-full text-sm">
                  개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고
                  시행하고 있습니다.
                </div>

                <div className="block w-full h-full text-lg py-3">
                  나. 해킹 등에 대비한 기술적 대책
                </div>
                <div className="block w-full h-full text-sm">
                  회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및
                  훼손을 막기 위하여 보안프로그램을 설치하고 주기적인
                  갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을
                  설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
                </div>

                <div className="block w-full h-full text-lg py-3">
                  다. 개인정보의 암호화
                </div>
                <div className="block w-full h-full text-sm">
                  이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고
                  있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송
                  데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도
                  보안기능을 사용하고 있습니다.
                </div>

                <div className="block w-full h-full text-lg py-3">
                  라. 접속기록의 보관 및 위변조 방지
                </div>
                <div className="block w-full h-full text-sm">
                  개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관,
                  관리하고 있으며,다만, 5만명 이상의 정보주체에 관하여
                  개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는
                  경우에는 2년이상 보관, 관리하고 있습니다. <br />
                  또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을
                  사용하고 있습니다.
                </div>

                <div className="block w-full h-full text-lg py-3">
                  마. 개인정보에 대한 접근 제한
                </div>
                <div className="block w-full h-full text-sm">
                  개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
                  부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여
                  필요한 조치를 하고 있으며 침입차단시스템을 이용하여
                  외부로부터의 무단 접근을 통제하고 있습니다.
                </div>

                <div className="block w-full h-full text-lg py-3">
                  사. 비인가자에 대한 출입 통제
                </div>
                <div className="block w-full h-full text-sm">
                  개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에
                  대해 출입통제 절차를 수립, 운영하고 있습니다.
                </div>
              </li>

              {/* 제8조 */}
              <li className="space-y-4 mb-4 mt-6">
                <div className="block w-full h-full text-xl py-3 font-bold">
                  제8조 [개인정보 자동 수집 장치의 설치•운영 및 거부에 관한
                  사항]
                </div>

                <div className="block w-full h-full text-lg py-3">
                  가. 쿠키의 사용
                </div>
                <div className="block w-full h-full text-sm">
                  ① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해
                  이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를
                  사용합니다.
                  <br />
                  <br />② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가
                  이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의
                  PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
                </div>

                <div className="block w-full h-full text-sm ml-3">
                  1{")"} <b>쿠키의 사용 목적 : </b>이용자가 방문한 각 서비스와
                  웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속
                  여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해
                  사용됩니다.
                  <br />
                  <br />2{")"} <b>쿠키의 설치•운영 및 거부 : </b>웹브라우저
                  상단의 도구 {">"}
                  인터넷 옵션 {">"} 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을
                  거부 할 수 있습니다.
                  <br />
                  <br />3{")"} 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에
                  어려움이 발생할 수 있습니다.
                </div>

                <div className="block w-full h-full text-lg py-3">
                  나. Google Analytics for Firebase를 통한 웹로그 분석
                </div>
                <div className="block w-full h-full text-sm">
                  ① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 Google
                  Analytics for Firebase를 통해 이용자의 서비스 이용에 대한
                  분석을 실행합니다.
                  <br />
                  <br />② 웹로그는 이용자가 어떻게 웹 서비스를 이용하고 있는지
                  분석하기 위해 수집하는 정보입니다.
                </div>

                <div className="block w-full h-full text-sm ml-3">
                  1{")"} <b>웹로그 사용 목적 : </b>이용자가 방문한 각 서비스와
                  웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속
                  여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해
                  사용됩니다.
                  <br />
                  <br />2{")"} <b>웹로그의 설치•운영 및 거부 : </b>
                  <a
                    href="https://tools.google.com/dlpage/gaoptout/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://tools.google.com/dlpage/gaoptout/
                  </a>
                  <br />
                  <br />3{")"} 웹로그 제공을 거부할 경우 맞춤형 서비스 이용에
                  어려움이 발생할 수 있습니다.
                </div>
              </li>

              {/* 제9조 */}
              <li className="space-y-4 mb-4 mt-6">
                <div className="block w-full h-full text-xl py-3 font-bold">
                  제9조 [개인정보 보호책임자]
                </div>

                <div className="block w-full h-full text-sm">
                  ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고,
                  개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을
                  위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                </div>

                <table className="table-fixed w-full bg-transparent border-collapse mt-3 mb-3">
                  <tbody>
                    <tr>
                      <th className="w-1/5 px-6 align-middle border border-solid py-3 border-l-0 font-semibold border-blueGray-100 text-center">
                        보호책임자 성명
                      </th>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 border-r-0 p-4">
                        김가현
                      </td>
                    </tr>

                    <tr>
                      <th className="w-1/5 px-6 align-middle border border-solid py-3 border-l-0 font-semibold border-blueGray-100 text-center">
                        직책
                      </th>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 border-r-0 p-4">
                        대표
                      </td>
                    </tr>

                    <tr>
                      <th className="w-1/5 px-6 align-middle border border-solid py-3 border-l-0 font-semibold border-blueGray-100 text-center">
                        직급
                      </th>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 border-r-0 p-4">
                        대표
                      </td>
                    </tr>

                    <tr>
                      <th className="w-1/5 px-6 align-middle border border-solid py-3 border-l-0 font-semibold border-blueGray-100 text-center">
                        담당자 성명
                      </th>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 border-r-0 p-4">
                        우현진
                      </td>
                    </tr>

                    <tr>
                      <th className="w-1/5 px-6 align-middle border border-solid py-3 border-l-0 font-semibold border-blueGray-100 text-center">
                        연락처
                      </th>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 border-r-0 p-4">
                        02-990-6078
                      </td>
                    </tr>

                    <tr>
                      <th className="w-1/5 px-6 align-middle border border-solid py-3 border-l-0 font-semibold border-blueGray-100 text-center">
                        이메일
                      </th>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 border-r-0 p-4">
                        <a href="mailto:dev.admin@majors.kr?body=Sent from https://www.majors.kr">
                          admin@majors.kr
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="block w-full h-full text-sm">
                  ② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서
                  발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에
                  관한 사항을 개인정보 보호책임자에게 문의하실 수 있습니다.
                  회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴
                  것입니다.
                </div>
              </li>

              {/* 제10조 */}
              <li className="space-y-4 mb-4 mt-6">
                <div className="block w-full h-full text-xl py-3 font-bold">
                  제10조 [개인정보 열람청구]
                </div>

                <div className="block w-full h-full text-sm">
                  정보주체는 ⌜개인정보 보호법⌟ 제35조에 따른 개인정보의 열람
                  청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의
                  개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
                </div>
                <div className="block w-full h-full text-lg py-3">
                  개인정보 열람청구 접수•처리 부서
                </div>
                <table className="table-fixed w-full bg-transparent border-collapse mt-3 mb-3">
                  <tbody>
                    <tr>
                      <th className="w-1/5 px-6 align-middle border border-solid py-3 border-l-0 font-semibold border-blueGray-100 text-center">
                        부서명
                      </th>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 border-r-0 p-4">
                        매니지먼트 팀
                      </td>
                    </tr>

                    <tr>
                      <th className="w-1/5 px-6 align-middle border border-solid py-3 border-l-0 font-semibold border-blueGray-100 text-center">
                        담당자
                      </th>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 border-r-0 p-4">
                        우현진
                      </td>
                    </tr>

                    <tr>
                      <th className="w-1/5 px-6 align-middle border border-solid py-3 border-l-0 font-semibold border-blueGray-100 text-center">
                        연락처
                      </th>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 border-r-0 p-4">
                        02-990-6078
                      </td>
                    </tr>

                    <tr>
                      <th className="w-1/5 px-6 align-middle border border-solid py-3 border-l-0 font-semibold border-blueGray-100 text-center">
                        이메일
                      </th>
                      <td className="order-t-0 px-6 border border-solid border-blueGray-100 align-middle border-l-0 border-r-0 p-4">
                        <a href="mailto:dev.admin@majors.kr?body=Sent from https://www.majors.kr">
                          admin@majors.kr
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>

              {/* 제11조 */}
              <li className="space-y-4 mb-4 mt-6">
                <div className="block w-full h-full text-xl py-3 font-bold">
                  제11조 [권익침해 구제방법]
                </div>
                <div className="block w-full h-full py-3 text-sm">
                  정보주체는 개인정보침해로 인한 구제를 받기 위하여
                  개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터
                  등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타
                  개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기
                  바랍니다.
                  <br />
                  <br />
                  1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (
                  <a
                    href="https://www.kopico.go.kr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.kopico.go.kr
                  </a>
                  ) <br />
                  2. 개인정보침해신고센터 : (국번없이) 118 (
                  <a
                    href="https://privacy.kisa.or.kr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy.kisa.or.kr
                  </a>
                  ) <br />
                  3. 대검찰청 : (국번없이) 1301 (
                  <a
                    href="https://www.spo.go.kr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.spo.go.kr
                  </a>
                  ) <br />
                  4. 경찰청 : (국번없이) 182 (
                  <a
                    href="https://ecrm.cyber.go.kr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ecrm.cyber.go.kr
                  </a>
                  )
                  <br />
                  <br />
                  「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의
                  정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한
                  요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여
                  권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에
                  따라 행정심판을 청구할 수 있습니다.
                  <br />
                  <br />※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(
                  <a
                    href="https://www.simpan.go.kr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.simpan.go.kr
                  </a>
                  ) 홈페이지를 참고하시기 바랍니다.
                </div>
              </li>

              {/* 제12조 */}
              <li className="space-y-4 mb-4">
                <div className="block w-full h-full text-xl py-3 font-bold">
                  제12조 [개인정보 처리방침 변경]
                </div>
                <div className="block w-full h-full py-3 text-sm">
                  본 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에
                  따른 변경 내용의 추가, 삭제, 정정이 있는 경우 변경사항의
                  시행일 7일 전부터, 이용자에게 불리하거나 중요한 내용의 경우
                  시행일 30일 전부터 고지하도록 하겠습니다.
                  <br />
                  <br />① 이 개인정보 처리방침은 2021. 9. 1부터 적용됩니다.
                </div>
              </li>
            </ul>
          </div>

          {/* Footer */}
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
