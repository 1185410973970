import React from "react";

const Video = () => {
  return (
    <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
      <video
        autoPlay
        muted
        // loop
        preload="auto"
        playsInline
        className="w-full rounded-lg shadow-lg mb-6"
      >
        <source
          src="https://res.cloudinary.com/duxjclncl/video/upload/f_auto:video,q_auto/kggsuprcrbpdgcku4o3z"
          type="video/mp4"
        />
      </video>
    </div>
  );
};

export default Video;
