import React from "react";
import CardTableTd from "./CardTableTd";

export const CardTableTr = ({ data, path }) => {
  return (
    <tbody>
      {data.map((item) => {
        return <CardTableTd key={item.createdAt} item={item} path={path} />;
      })}
    </tbody>
  );
};

export default CardTableTr;
