import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// components
import CardTableTr from "./CardTableTr";

// Firebase Database
import firebase from "../../firebase/fbase";

export default function CardTable({ color, path }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const dbRef = firebase.database().ref(`applications`);
    const dbRef2 = firebase.database().ref(`applications/${path}`);
    path === "business"
      ? dbRef2
          .once("value")
          .then((snapshot) => {
            const allData = snapshot.val()
              ? Object.entries(snapshot.val()).map(([key, app]) => ({
                  _id: key,
                  createdAt: app.createdAt,
                  name: app.name,
                  phone: app.phone,
                  email: app.email,
                  category: app.category,
                  snsurl: app.snsurl,
                  cert: app.cert,
                  naver: app.naver,
                  funnel: app.funnel,
                  funnelEtc: app.funnelEtc,
                  policy: app.policy,
                }))
              : [];
            setData(allData);
          })
          .catch((err) => {
            console.error("Data read error: " + err);
          })
      : dbRef
          .once("value")
          .then((snapshot) => {
            const applications = snapshot.val();
            const allData = applications
              ? Object.keys(applications)
                  .map((key) => {
                    const appEntries = applications[key];
                    return Object.keys(appEntries).map((subKey) => {
                      const app = appEntries[subKey];
                      return {
                        _id: subKey,
                        createdAt: app.createdAt,
                        name: app.name,
                        phone: app.phone,
                        email: app.email,
                        category: app.category,
                        snsurl: app.snsurl,
                        cert: app.cert,
                        naver: app.naver,
                        funnel: app.funnel,
                        funnelEtc: app.funnelEtc,
                        policy: app.policy,
                      };
                    });
                  })
                  .flat()
              : [];
            setData(allData);
          })
          .catch((err) => {
            console.error("Data read error: " + err);
            setError(err);
          })
          .finally(() => {
            setLoading(false);
          });
  }, [path]);

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        {/* 표 제목 */}
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                틱톡커 지원자 목록 {" [총 " + data.length + " 명]"}
              </h3>
            </div>
          </div>
        </div>

        {/* 지원자 목록 */}
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse table-fixed mxxl:table-auto">
            <colgroup>
              {/* 지원일자 */}
              <col width="10%"></col>
              {/* 이름 */}
              <col width="8%"></col>
              {/* 연락처 */}
              <col width="10%"></col>
              {/* 이메일 */}
              <col width="15%"></col>
              {/* 카테고리 */}
              <col width="10%"></col>
              {/* SNS */}
              <col width="15%"></col>
              {/* 자격증 */}
              <col width="20%"></col>
              {/* 네이버 인플루언서 */}
              <col width="8%"></col>
              <col width="10%"></col>
            </colgroup>
            {/* 열 제목 */}
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  지원일자
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  {path === "apply" ? "이름" : "브랜드"}
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  연락처
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  이메일
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  카테고리
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  {path === "apply" ? "SNS URL" : "내용"}
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  {path === "apply" ? "자격증" : "담당자"}
                </th>

                {path === "apply" ? (
                  <th
                    className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold " +
                      (color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    네이버
                    <br />
                    인플루언서
                  </th>
                ) : null}

                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  유입경로
                </th>
                {/* Dropdown 메뉴용 */}
                {/* <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ></th> */}
              </tr>
            </thead>
            {/* 각 행 */}
            <CardTableTr data={data} path={path} />
          </table>
        </div>
      </div>
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
