import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import DatabaseService from "../../firebase/db";
import { fbAnalytics } from "firebase/fbase";
import "../../assets/styles/custom.css";

function twoDigits(digit) {
  digit = digit < 10 ? "0" + digit + "" : digit + "";
  return digit;
}

export default function InfluencerForm() {
  return (
    <>
      <Formik
        initialValues={{
          image: "",
          name: "",
          category: "",
          url: {
            tiktok: "",
            instagram: "",
            youtube: "",
          },
        }}
        validationSchema={Yup.object({
          image: Yup.string().required("필수 입력 항목입니다."),
          name: Yup.string().required("필수 입력 항목입니다."),
          category: Yup.string().required("필수 입력 항목입니다."),
          url: Yup.object({
            tiktok: Yup.string()
              .url("유효하지 않은 URL 형식입니다.")
              .notRequired(),
            instagram: Yup.string()
              .url("유효하지 않은 URL 형식입니다.")
              .notRequired(),
            youtube: Yup.string()
              .url("유효하지 않은 URL 형식입니다.")
              .notRequired(),
          }),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          fbAnalytics.logEvent("select_content", {
            content_type: "applyBtn",
            content_id: "applyBtn",
          });
          const today = new Date();

          const year = today.getFullYear();
          let month = today.getMonth() + 1;
          month = twoDigits(month);
          let day = today.getDate();
          day = twoDigits(day);

          const currentDate = year + "/" + month + "/" + day;

          let hour = today.getHours();
          let min = today.getMinutes();
          let sec = today.getSeconds();

          hour = twoDigits(hour);
          min = twoDigits(min);
          sec = twoDigits(sec);

          const currentTime = hour + ":" + min + ":" + sec;
          let err = false;

          DatabaseService.create(
            "influencer",
            {
              createdAt: currentDate + " " + currentTime,
              image: values.image,
              name: values.name,
              category: values.category,
              url: {
                tiktok: values.url.tiktok,
                instagram: values.url.instagram,
                youtube: values.url.youtube,
              },
            },
            (error) => {
              err = true;
              alert("에러가 발생하였습니다.\n에러메세지: " + error);
            }
          );

          if (!err) {
            alert("제출이 완료되었습니다!");
            setSubmitting(false);
            resetForm({});
          } else {
            console.error("데이터 저장 중 에러 발생: ", err);
          }
        }}
      >
        {({ values }) => (
          <section className="border-none bg-white relative block py-24 lg:pt-0 p-4">
            <div className="font-normal mini:text-xxs text-xs leading-relaxed pt-6 text-red-500">
              * 표시 항목은 필수 입력 항목입니다. 미입력 시 지원이 완료되지
              않습니다.
            </div>

            <Form>
              {/* 이름 */}
              <div className="relative w-full mb-5 mt-4">
                <label
                  className="mini:text-xs block uppercase text-blueGray-600 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  이름 <span className="text-red-500">*</span>{" "}
                  <ErrorMessage
                    name="name"
                    render={(msg) => (
                      <span className="form-err-msg font-normal align-middle inline-flex ml-5 text-xxs text-red-500">
                        {msg}
                      </span>
                    )}
                  />
                </label>
                <Field
                  name="name"
                  type="text"
                  className="mini:text-xs border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>

              {/* 이미지 url */}
              <div className="relative w-full mb-5">
                <label
                  className="mini:text-xs block uppercase text-blueGray-600 text-sm font-bold mb-2"
                  htmlFor="image"
                >
                  이미지 URL <span className="text-red-500">*</span>{" "}
                  <ErrorMessage
                    name="image"
                    render={(msg) => (
                      <span className="form-err-msg font-normal align-middle inline-flex ml-5 text-xxs text-red-500">
                        {msg}
                      </span>
                    )}
                  />
                </label>
                <Field
                  name="image"
                  type="text"
                  placeholder="예: 캘리그라피, 드로잉, 외국어, IT 등"
                  className="mini:text-xs border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>

              {/* 카테고리 */}
              <div className="relative w-full mb-5">
                <label
                  className="mini:text-xs block uppercase text-blueGray-600 text-sm font-bold mb-2"
                  htmlFor="category"
                >
                  카테고리 <span className="text-red-500">*</span>{" "}
                  <ErrorMessage
                    name="category"
                    render={(msg) => (
                      <span className="form-err-msg font-normal align-middle inline-flex ml-5 text-xxs text-red-500">
                        {msg}
                      </span>
                    )}
                  />
                </label>
                <Field
                  name="category"
                  type="text"
                  placeholder="담당자 이름을 적어주세요"
                  className="mini:text-xs border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>

              {/* TikTok URL */}
              <div className="relative w-full mb-5">
                <label
                  className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                  htmlFor="url.tiktok"
                >
                  TikTok URL
                </label>
                <Field
                  name="url.tiktok"
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="https://www.tiktok.com/@yourusername"
                />
                <ErrorMessage
                  name="url.tiktok"
                  render={(msg) => (
                    <span className="form-err-msg font-normal align-middle inline-flex ml-5 text-xxs text-red-500">
                      {msg}
                    </span>
                  )}
                />
              </div>
              {/* Instagram URL */}
              <div className="relative w-full mb-5">
                <label
                  className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                  htmlFor="url.instagram"
                >
                  Instagram URL
                </label>
                <Field
                  name="url.instagram"
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="https://www.instagram.com/yourusername"
                />
                <ErrorMessage
                  name="url.instagram"
                  render={(msg) => (
                    <span className="form-err-msg font-normal align-middle inline-flex ml-5 text-xxs text-red-500">
                      {msg}
                    </span>
                  )}
                />
              </div>
              {/* YouTube URL */}
              <div className="relative w-full mb-5">
                <label
                  className="block uppercase text-blueGray-600 text-sm font-bold mb-2"
                  htmlFor="url.youtube"
                >
                  YouTube URL
                </label>
                <Field
                  name="url.youtube"
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="https://www.youtube.com/channel/yourchannelid"
                />
                <ErrorMessage
                  name="url.youtube"
                  render={(msg) => (
                    <span className="form-err-msg font-normal align-middle inline-flex ml-5 text-xxs text-red-500">
                      {msg}
                    </span>
                  )}
                />
              </div>

              {/* 제출 버튼 */}
              <div className="text-center mt-6">
                <button
                  className="mini:text-xs bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  인플루언서 등록
                </button>
              </div>
            </Form>
          </section>
        )}
      </Formik>
    </>
  );
}
