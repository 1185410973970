/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

// components
import CardSocialTraffic from "components/Cards/CardSocialTraffic.js";
import CardPageVisits from "components/Cards/CardPageVisits";

// Firebase Database
import firebase from "../../firebase/fbase";

export default function SocialTraffics() {
  const [data, setData] = useState({
    userCount: 0,
    instagram: 0,
    instagramRatio: 0,
    facebook: 0,
    facebookRatio: 0,
    naverBlog: 0,
    naverBlogRatio: 0,
    naverCafe: 0,
    naverCafeRatio: 0,
    article: 0,
    articleRatio: 0,
    interview: 0,
    interviewRatio: 0,
    etc: 0,
    etcRatio: 0,
    undef: 0,
    etcTxtCnt: {},
  });

  useEffect(() => {
    const dbRef = firebase.database().ref("applications");
    dbRef
      .once("value")
      .then((snapshot) => {
        let allSources = { ...data };

        snapshot.forEach((applicationsSnapshot) => {
          applicationsSnapshot.forEach((applicationSnapshot) => {
            const application = applicationSnapshot.val();
            allSources.userCount += 1;

            const etcContent = application.funnelEtc;
            if (etcContent) {
              allSources.etcTxtCnt[etcContent] =
                (allSources.etcTxtCnt[etcContent] || 0) + 1;
            }

            if (application.funnel) {
              application.funnel.forEach((source) => {
                if (source && allSources.hasOwnProperty(source)) {
                  allSources[source] += 1;
                } else {
                  allSources.undef += 1;
                }
              });
            }
          });
        });

        const calculateRatio = (sourceCount) =>
          allSources.userCount > 0
            ? Math.round((sourceCount * 100) / allSources.userCount)
            : 0;
        const sources = [
          "instagram",
          "facebook",
          "naverBlog",
          "naverCafe",
          "article",
          "interview",
          "etc",
        ];
        sources.forEach((source) => {
          allSources[`${source}Ratio`] = calculateRatio(allSources[source]);
        });

        setData(allSources);
      })
      .catch((err) => {
        console.error("Data read error: ", err);
      });
  }, []);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-8/12 xl:w-6/12 px-4">
          <CardSocialTraffic trafficSources={data} />
        </div>

        <div className="w-full lg:w-5/12 xl:w-5/12 px-4">
          <CardPageVisits etcSource={data.etcTxtCnt} />
        </div>
      </div>
      {/* <div className="flex flex-wrap mt-4">
        <div className="w-full lg:w-8/12 xl:w-4/12 px-4">
          <CardPageVisits etcSource={data.etcTxtCnt} />
        </div>
      </div> */}
    </>
  );
}
